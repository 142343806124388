<template>
    <div>
        <v-data-table
            dense
            :header-props="{
                sortIcon: null,
            }"
            :headers="tableHeader"
            :items="data"
            :items-per-page="-1"
            fixed-header
            :customSort="(item) => item"
            height="750"
            :loading="loading"
            :items-per-page.sync="offset"
            :page.sync="currentPage"
            :server-items-length="dataAmount"
            :footer-props="{
                pagination: {
                    page: currentPage,
                    itemsPerPage: offset,
                    pageStart: (currentPage - 1) * offset,
                    pageStop: currentPage * offset,
                    pageCount: Math.ceil(dataAmount / offset),
                    itemsLength: dataAmount,
                },
                'items-per-page-text': '',
                'items-per-page-options': [30, 45, 60],
            }"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
            <template v-slot:header.login="{ header }">
                {{ header.text }}
                <v-menu
                    v-model="loginSearchMenu"
                    offset-y
                    :close-on-content-click="false"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.prevent="onSearch('loginSearch')"
                            id="loginSearch"
                        >
                            mdi-magnify
                        </v-icon>
                    </template>
                    <div style="background-color: #1e1e1e; width: 280px">
                        <v-text-field
                            v-model="loginSearch"
                            class="px-4"
                            type="text"
                            ref="loginSearch"
                            label="Enter login to search"
                            style="width: 100%"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-btn
                            @click="
                                handleChange();
                                loginSearchMenu = false;
                            "
                            small
                            text
                            color="primary"
                            class="ml-2 mb-2"
                            style="padding: 6px"
                        >
                            Search
                        </v-btn>
                        <v-btn
                            @click="
                                loginSearch = '';
                                handleChange();
                                loginSearchMenu = false;
                            "
                            small
                            text
                            color="red"
                            class="ml-2 mb-2"
                            style="padding: 6px"
                        >
                            Clear
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:item.login="{ item }">
                <a
                    color="primary"
                    @click="redirectTo('info', item.login, item.server_id)"
                >
                    {{ item.login }}
                </a>
            </template>
            <template v-slot:item.total_pnl="{ item }">
                {{ item.total_pnl.toFixed(2) }}
            </template>

            <template v-slot:item.current_risk_level="{ item }">
                <div style="white-space: nowrap">
                    <v-menu offset-y close-on-content-click bottom>
                        <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip
                                top
                                @input="
                                    (visible) =>
                                        debouncedFetchTooltipData(
                                            visible,
                                            item,
                                            'current'
                                        )
                                "
                            >
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-chip
                                        v-bind="attrs"
                                        v-on="{ ...tooltip, ...menu }"
                                        x-small
                                        :key="item.index"
                                        :style="{
                                            paddingLeft:
                                                item.current_risk_level < 0
                                                    ? '30px'
                                                    : '12px',
                                        }"
                                        >{{
                                            getRiskLevelOptionName(
                                                item.current_risk_level
                                            )
                                        }}</v-chip
                                    >
                                </template>
                                <span v-if="tooltipLoading">Loading...</span>
                                <span v-else>{{ tooltipText }}</span>
                            </v-tooltip>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(i, index) in riskLevelOption.filter(
                                    (option) =>
                                        option.id !== item.current_risk_level
                                )"
                                :key="index"
                                @click="changeRiskLevel(item, i.id)"
                            >
                                <v-list-item-title>{{
                                    i.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-tooltip top v-if="item.is_today === false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                style="color: orange; font-size: 16px"
                            >
                                mdi-alert
                            </v-icon>
                        </template>
                        <span>Latest risk level was set before today.</span>
                    </v-tooltip>
                    <v-btn
                        v-if="checkVisibility(item.current_user_id)"
                        icon
                        x-small
                        :disabled="loading"
                        @click="revertRiskLevel(item)"
                        color="blue"
                        class="ma-2 white--text"
                        style="margin: 0px !important"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">
                                    mdi-undo
                                </v-icon>
                            </template>
                            <span
                                >Click to revert your last risk level change
                                action</span
                            >
                        </v-tooltip>
                    </v-btn>
                </div>
            </template>

            <template v-slot:item.watchListAction="{ item }">
                <span
                    ><v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                color="green darken-2"
                                x-small
                                :disabled="!item.watchlist"
                                @click="
                                    redirectTo(
                                        'view',
                                        item.login,
                                        item.server_id,
                                        item.watchlist
                                    )
                                "
                            >
                                <v-icon> mdi-eye </v-icon>
                            </v-btn>
                        </template>
                        <span>Detail of login Watchlist</span>
                    </v-tooltip>
                </span>
                <span
                    ><v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                color="yellow darken-2"
                                x-small
                                :disabled="!!item.watchlist"
                                @click="
                                    redirectTo(
                                        'add',
                                        item.login,
                                        item.server_id
                                    )
                                "
                            >
                                <v-icon> mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Add login to Watchlist</span>
                    </v-tooltip>
                </span>
                <span
                    ><v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                color="orange darken-2"
                                x-small
                                :disabled="!item.watchlist"
                                @click="redirectTo('edit', item.login)"
                            >
                                <v-icon> mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Watchlist</span>
                    </v-tooltip>
                </span>
                <!-- <span
                    ><v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                color="blue darken-2"
                                x-small
                                @click="
                                    redirectTo(
                                        'info',
                                        item.login,
                                        item.server_id
                                    )
                                "
                            >
                                <v-icon> mdi-information</v-icon>
                            </v-btn>
                        </template>
                        <span>View Login detail</span>
                    </v-tooltip>
                </span> -->
            </template>
        </v-data-table>

        <WatchlistDisplayModal
            :watchlistModal="watchlistModal"
            :watchlistData="watchlistData"
            :closeModal="
                () => {
                    watchlistModal = false;
                }
            "
        />
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
    getAlertHubTableGroupedData,
    changeRiskLevelData,
    getRiskLevelData,
    revertRiskLevelData,
} from "@services/alert-hub";
import { helper } from "@components/mixins/helper";
import WatchlistDisplayModal from "./WatchlistDisplayModal";
import common from "@assets/js/common";
import { snackbar } from "@components/mixins/snackbar";

export default {
    props: {
        intervalTime: Number,
        riskLevelOption: [],
    },
    mixins: [helper, snackbar],
    components: {
        WatchlistDisplayModal,
    },
    data() {
        return {
            tableHeader: [
                { text: "Login", value: "login", align: "left", width: 60 },
                {
                    text: "Server",
                    value: "server_name",
                    align: "left",
                    width: 60,
                },
                {
                    text: "Book",
                    value: "book_name",
                    align: "left",
                    width: 60,
                },
                {
                    text: "Total PNL",
                    value: "total_pnl",
                    align: "left",
                    width: 100,
                },
                {
                    text: "Risk Level",
                    value: "current_risk_level",
                    align: "left",
                    sortable: false,
                    width: 100,
                },
                {
                    text: "WatchList Action",
                    value: "watchListAction",
                    align: "left",
                    sortable: false,
                    width: 140,
                },
            ],
            data: [],
            loading: false,
            watchlistModal: false,
            watchlistData: {},
            tooltipLoading: false,
            tooltipText: "",
            lastFetchingServer: null,
            lastFetchingLogin: null,
            lastFetchingRiskLevel: null,
            interval: null,

            offset: 30,
            currentPage: 1,
            dataAmount: 0,
            sortBy: ["login"],
            sortDesc: false,
            fetchTimeout: null,
            loginSearchMenu: false,
            loginSearch: "",
        };
    },
    computed: {
        ...mapState("AlertHub", ["selectedProfile", "triggered"]),
    },
    watch: {
        selectedProfile(nv, ov) {
            if (
                JSON.stringify(nv) !== JSON.stringify(ov) &&
                nv?.selected &&
                nv?.book &&
                nv?.server
            ) {
                this.handleChange();
            }
        },
        intervalTime() {
            this.handleChange();
        },
        triggered(nv) {
            if (nv) {
                this.handleChange();
                setTimeout(() => {
                    this.updateTriggerStatus(false);
                }, 0);
            }
        },
        offset() {
            this.handleChange();
        },
        currentPage() {
            this.handleChange();
        },
        sortBy() {
            this.handleChange();
        },
        sortDesc() {
            this.handleChange();
        },
    },
    methods: {
        ...mapActions("AlertHub", ["updateTriggerStatus"]),
        onSearch(field) {
            setTimeout(() => {
                this.$refs[field].focus();
            }, 200);
        },
        handleChange() {
            clearTimeout(this.fetchTimeout);
            this.fetchTimeout = setTimeout(() => {
                clearInterval(this.interval);
                this.fetchTableData();
                this.interval = setInterval(
                    () => this.fetchTableData(),
                    this.intervalTime
                );
            }, 100);
        },
        debouncedFetchTooltipData(visible, item, type) {
            if (visible) {
                this.tooltipTimer = setTimeout(() => {
                    this.fetchTooltipData(true, item, type);
                }, 200);
            } else {
                clearTimeout(this.tooltipTimer);
            }
        },
        fetchTableData() {
            this.loading = true;
            const params = {
                startOfDay:
                    common.getMT4TimeString().split(" ")[0] + " 00:00:00",
                selected_alert: this.selectedProfile.selected,
                selected_book: this.selectedProfile.book,
                selected_server: this.selectedProfile.server,
                offset: this.offset,
                currentPage: this.currentPage,
                sort_by:
                    typeof this.sortBy === "object" && this.sortBy.length > 0
                        ? this.sortBy[0]
                        : undefined,
                sortDesc: this.sortDesc,
                login_filter: this.loginSearch,
            };
            getAlertHubTableGroupedData(params).then((res) => {
                this.data = res.data.data;
                this.dataAmount = res.data.count;
                this.loading = false;
            });
        },
        getRiskLevelOptionName(riskLevelId) {
            if (parseInt(riskLevelId) > 0) {
                const allocatedOption = this.riskLevelOption.find(
                    (option) => option.id === parseInt(riskLevelId)
                );
                return allocatedOption?.name || "";
            } else {
                return "";
            }
        },
        changeRiskLevel(rowInfo, changeId) {
            const params = {
                login: rowInfo.login,
                server_id: rowInfo.server_id,
                risk_level_id: changeId,
                mt_time: common.getMT4TimeString(),
            };
            changeRiskLevelData(params).then(() => {
                this.updateTriggerStatus(true);
            });
        },
        async fetchTooltipData(visible, item, type) {
            if (visible) {
                const fetchingLevel =
                    type === "prev"
                        ? item.prev_risk_level
                        : item.current_risk_level;
                if (fetchingLevel >= 0) {
                    this.tooltipLoading = true;
                    this.lastFetchingLogin = item.login;
                    this.lastFetchingServer = item.server_id;
                    this.lastFetchingRiskLevel = fetchingLevel;

                    const params = {
                        server_id: item.server_id,
                        login: item.login,
                        risk_level_id: fetchingLevel,
                    };
                    getRiskLevelData(params).then(({ data }) => {
                        if (
                            this.lastFetchingLogin === item.login &&
                            this.lastFetchingServer === item.server_id &&
                            this.lastFetchingRiskLevel === params.risk_level_id
                        ) {
                            this.tooltipText = data.length
                                ? `${data[0].name} - ${data[0].record_time}`
                                : "No risk level data found";
                            this.tooltipLoading = false;
                        }
                    });
                } else {
                    this.tooltipText = "";
                }
            }
        },
        redirectTo(type, login, server, info) {
            const currentURL = window.location.origin;
            const redirectURL = currentURL.includes("localhost")
                ? currentURL.replace("7001", "80")
                : currentURL.replace("pk", "riskhub");
            switch (type) {
                case "info":
                    window.open(
                        redirectURL +
                            `/tag-centre/account-analysis/${server}-${login}`,
                        "_blank"
                    );
                    break;
                case "add":
                    window.open(
                        redirectURL +
                            `/watch-list?mode=add&login=${login}&server=${server}`,
                        "_blank"
                    );
                    break;
                case "edit":
                    window.open(
                        redirectURL + `/watch-list?mode=edit&login=${login}`,
                        "_blank"
                    );
                    break;
                case "view":
                    this.watchlistData = info;
                    this.watchlistModal = true;
                    break;
                default:
                    break;
            }
        },
        checkVisibility(current_user_id) {
            if (current_user_id === parseInt(localStorage.getItem("user_id"))) {
                return true;
            }
            return false;
        },
        revertRiskLevel(rowInfo) {
            const params = {
                login: rowInfo.login,
                server_id: rowInfo.server_id,
            };
            revertRiskLevelData(params)
                .then(({ data }) => {
                    this.snackBarSuccess(data);
                })
                .catch(({ data }) => {
                    console.error(data);
                    this.snackBarDanger(data);
                })
                .finally(() => {
                    this.updateTriggerStatus(true);
                });
        },
    },
    destroyed() {
        clearInterval(this.interval);
    },
};
</script>

<style scoped>
::v-deep(.v-data-table > .v-data-table__wrapper > table > thead > tr > th),
::v-deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td) {
    padding: 0 8px; /* Top/Bottom, Left/Right */
}
</style>
