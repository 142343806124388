<template>
    <v-row>
        <v-col cols="8" style="padding-right: 4px">
            <v-card class="d-flex flex-column" style="height: 100%" outlined>
                <v-card-title style="border-bottom: 1px solid #404040">
                    <div
                        class="text-left"
                        style="
                            margin-left: 0;
                            width: 50%;
                            font-size: 13px;
                            font-weight: normal;
                        "
                    >
                        Alert Info
                    </div>
                </v-card-title>

                <v-divider></v-divider>

                <ComponentAlertInfo :riskLevelOption="riskLevelOption" />
            </v-card>
        </v-col>
        <v-col cols="4" style="padding-left: 4px">
            <v-card class="d-flex flex-column" style="height: 100%" outlined>
                <v-card-title style="border-bottom: 1px solid #404040">
                    <div
                        class="text-left"
                        style="
                            margin-left: 0;
                            width: 50%;
                            font-size: 13px;
                            font-weight: normal;
                        "
                    >
                        Account Info
                    </div>
                    <div class="text-right" style="margin-right: 0; width: 50%">
                        <v-menu offset-y dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    x-small
                                    color="default"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-1"
                                >
                                    {{
                                        detailIntervalOptions.find(
                                            (option) =>
                                                option.value ===
                                                selectedInterval
                                        ).key
                                    }}
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    v-for="item in detailIntervalOptions"
                                    :key="item.key"
                                    @click="
                                        () => {
                                            selectedInterval = item.value;
                                        }
                                    "
                                >
                                    <v-list-item-title>{{
                                        item.key
                                    }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-card-title>

                <v-divider></v-divider>

                <ComponentAccountInfo
                    :intervalTime="selectedInterval"
                    :riskLevelOption="riskLevelOption"
                />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ComponentAlertInfo from "./ComponentAlertInfo";
import ComponentAccountInfo from "./ComponentAccountInfo";
import { getRiskLevelOption } from "@services/alert-hub";
import { mapActions } from "vuex";

export default {
    components: {
        ComponentAlertInfo,
        ComponentAccountInfo,
    },
    data() {
        return {
            selectedInterval: 20000,
            detailIntervalOptions: [
                { key: "20s", value: 20000 },
                { key: "30s", value: 30000 },
                { key: "60s", value: 60000 },
            ],
            riskLevelOption: [],
        };
    },
    methods: {
        ...mapActions("AlertHub", ["getRHUsersAction"]),
    },
    mounted() {
        getRiskLevelOption().then(({ data }) => {
            this.riskLevelOption = data;
        });
        this.getRHUsersAction();
    },
    destroyed() {},
};
</script>
