<template>
    <v-dialog
        width="1000"
        :hide-overlay="true"
        :persistent="true"
        v-model="watchlistModal"
    >
        <v-card class="pt-0">
            <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
                style="background: rgb(26, 57, 88)"
            >
                <v-toolbar-title class="text-h6 white--text pl-0">
                    Watchlist Detail
                </v-toolbar-title>
                <v-spacer />

                <v-btn color="white" icon @click="closeModal"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-app-bar>

            <v-card-actions>
                <v-row>
                    <v-col cols="2"><strong>Login</strong></v-col>
                    <v-col cols="4">{{ watchlistData.login }}</v-col>

                    <v-col cols="2"><strong>Server</strong></v-col>
                    <v-col cols="4">{{ watchlistData.server_name }}</v-col>

                    <v-col cols="2"><strong>Book</strong></v-col>
                    <v-col cols="4">{{ watchlistData.book }}</v-col>

                    <v-col cols="2"><strong>Account CCY</strong></v-col>
                    <v-col cols="4">{{ watchlistData.currency }}</v-col>

                    <v-col cols="2"><strong>Equity(USD)</strong></v-col>
                    <v-col cols="4">{{
                        (watchlistData.equity_usd || 0).toFixed(2)
                    }}</v-col>

                    <v-col cols="2"><strong>Balance(USD)</strong></v-col>
                    <v-col cols="4">{{
                        (watchlistData.balance_usd || 0).toFixed(2)
                    }}</v-col>

                    <v-col cols="2"><strong>Total PNL(USD)</strong></v-col>
                    <v-col cols="4">{{
                        (watchlistData.profit || 0).toFixed(2)
                    }}</v-col>

                    <v-col cols="2"><strong>DPM</strong></v-col>
                    <v-col cols="4">{{
                        (watchlistData.dpm || 0).toFixed(2)
                    }}</v-col>

                    <v-col cols="2"><strong>Volume(USD)</strong></v-col>
                    <v-col cols="4">{{
                        (watchlistData.volume_usd || 0).toFixed(2)
                    }}</v-col>

                    <v-col cols="2"><strong>Category</strong></v-col>
                    <v-col cols="4">{{
                        (watchlistData.hashtag_name || "")
                            .replaceAll('"', "")
                            .replace("[", "")
                            .replace("]", "")
                    }}</v-col>

                    <v-col cols="2"><strong>Status</strong></v-col>
                    <v-col cols="4">{{ watchlistData.status_name }}</v-col>

                    <v-col cols="2"><strong>Assignee</strong></v-col>
                    <v-col cols="4">{{ getAssignee(watchlistData.user_ids )}}</v-col>

                    <v-col cols="2"><strong>Created By</strong></v-col>
                    <v-col cols="4">{{
                        watchlistData.watchlist_create_user
                    }}</v-col>

                    <v-col cols="2"><strong>Updated At</strong></v-col>
                    <v-col cols="4">{{
                        (watchlistData.watchlist_updated_at || "")
                            .split(".")[0]
                            .replace("T", " ")
                    }}</v-col>

                    <v-col cols="2"><strong>Comment</strong></v-col>
                    <v-col cols="10">{{ watchlistData.comment_text }}</v-col>
                </v-row>
            </v-card-actions>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeModal"
                    >Close</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        watchlistModal: false,
        watchlistData: null,
        closeModal: Function,
    },
    computed:{
        ...mapState("AlertHub", ["listRHUser"]),
    },
    methods:{
        getAssignee(id){
            if(id?.length>0){
                return this.listRHUser.find(user=>user.id === id[0])?.username
            }
            return ''
        }
    }
};
</script>
