var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"dense":"","header-props":{
            sortIcon: null,
        },"headers":_vm.tableHeader,"items":_vm.data,"items-per-page":-1,"fixed-header":"","height":"750","customSort":(item) => item,"loading":_vm.loading,"items-per-page":_vm.offset,"page":_vm.currentPage,"server-items-length":_vm.dataAmount,"footer-props":{
            pagination: {
                page: _vm.currentPage,
                itemsPerPage: _vm.offset,
                pageStart: (_vm.currentPage - 1) * _vm.offset,
                pageStop: _vm.currentPage * _vm.offset,
                pageCount: Math.ceil(_vm.dataAmount / _vm.offset),
                itemsLength: _vm.dataAmount,
            },
            'items-per-page-text': '',
            'items-per-page-options': [30, 45, 60],
        },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:itemsPerPage":function($event){_vm.offset=$event},"update:items-per-page":function($event){_vm.offset=$event},"update:page":function($event){_vm.currentPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header.login",fn:function({ header }){return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","id":"loginSearch"},on:{"click":function($event){$event.preventDefault();return _vm.onSearch('loginSearch')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}],null,true),model:{value:(_vm.loginSearchMenu),callback:function ($$v) {_vm.loginSearchMenu=$$v},expression:"loginSearchMenu"}},[_c('div',{staticStyle:{"background-color":"#1e1e1e","width":"280px"}},[_c('v-text-field',{ref:"loginSearch",staticClass:"px-4",staticStyle:{"width":"100%"},attrs:{"type":"text","label":"Enter login to search","single-line":"","hide-details":""},model:{value:(_vm.loginSearch),callback:function ($$v) {_vm.loginSearch=$$v},expression:"loginSearch"}}),_c('v-btn',{staticClass:"ml-2 mb-2",staticStyle:{"padding":"6px"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.handleChange();
                            _vm.loginSearchMenu = false;}}},[_vm._v(" Search ")]),_c('v-btn',{staticClass:"ml-2 mb-2",staticStyle:{"padding":"6px"},attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.loginSearch = '';
                            _vm.handleChange();
                            _vm.loginSearchMenu = false;}}},[_vm._v(" Clear ")])],1)])]}},{key:"item.login",fn:function({ item }){return [_c('a',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.redirectTo('info', item.login, item.server_id)}}},[_vm._v(" "+_vm._s(item.login)+" ")])]}},{key:"item.book_name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.book_name.replace(/[\[\]"']/g, ""))+" ")]}},{key:"item.total_pnl",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total_pnl.toFixed(2)))+" ")]}},{key:"item.alert_name",fn:function({ item }){return [_c('a',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showAlertModal(item.alert_name, item.alert_body)}}},[_vm._v(" "+_vm._s(_vm.capitalizeKey(item.alert_name, "-", " "))+" ")])]}},{key:"item.prev_risk_level",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},on:{"input":(visible) =>
                        _vm.debouncedFetchTooltipData(visible, item, 'prev')},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.prev_risk_level >= 0),expression:"item.prev_risk_level >= 0"}],style:({
                            paddingLeft:
                                item.current_risk_level < 0
                                    ? '30px'
                                    : '12px',
                            fontSize: '12px',
                        }),attrs:{"x-small":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(_vm.getRiskLevelOptionName(item.prev_risk_level)))])]}}],null,true)},[(_vm.tooltipLoading)?_c('span',[_vm._v("Loading...")]):_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])]}},{key:"item.current_risk_level",fn:function({ item }){return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c('v-tooltip',{attrs:{"top":""},on:{"input":(visible) =>
                                    _vm.debouncedFetchTooltipData(
                                        visible,
                                        item,
                                        'current'
                                    )},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-chip',_vm._g(_vm._b({key:item.index,style:({
                                        paddingLeft:
                                            item.current_risk_level < 0
                                                ? '30px'
                                                : '12px',
                                        fontSize: '12px',
                                    }),attrs:{"x-small":""}},'v-chip',attrs,false),{ ...tooltip, ...menu }),[_vm._v(_vm._s(_vm.getRiskLevelOptionName( item.current_risk_level )))])]}}],null,true)},[(_vm.tooltipLoading)?_c('span',[_vm._v("Loading...")]):_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])]}}],null,true)},[_c('v-list',{attrs:{"disabled":_vm.loading}},_vm._l((_vm.riskLevelOption.filter(
                                (option) =>
                                    option.id !== item.current_risk_level
                            )),function(i,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeRiskLevel(item, i.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(i.name))])],1)}),1)],1),(item.is_today === false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"orange","font-size":"16px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v("Latest risk level was set before today.")])]):_vm._e(),(_vm.checkVisibility(item.current_user_id))?_c('v-btn',{staticClass:"ma-2 white--text",staticStyle:{"margin":"0px !important"},attrs:{"icon":"","x-small":"","disabled":_vm.loading,"color":"blue"},on:{"click":function($event){return _vm.revertRiskLevel(item)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-undo ")])]}}],null,true)},[_c('span',[_vm._v("Click to revert your last risk level change action")])])],1):_vm._e()],1)]}},{key:"item.watchListAction",fn:function({ item }){return [_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green darken-2","x-small":"","disabled":!item.watchlist},on:{"click":function($event){return _vm.redirectTo(
                                    'view',
                                    item.login,
                                    item.server_id,
                                    item.watchlist
                                )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Detail of login Watchlist")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"yellow darken-2","x-small":"","disabled":!!item.watchlist},on:{"click":function($event){return _vm.redirectTo(
                                    'add',
                                    item.login,
                                    item.server_id
                                )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add login to Watchlist")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"orange darken-2","x-small":"","disabled":!item.watchlist},on:{"click":function($event){return _vm.redirectTo('edit', item.login)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Watchlist")])])],1)]}}])}),_c('AlertDisplayModal',{attrs:{"alertModal":_vm.alertModal,"alertModalType":_vm.alertModalType,"alertDetail":_vm.alertDetail,"closeModal":() => {
                _vm.alertModal = false;
            }}}),_c('WatchlistDisplayModal',{attrs:{"watchlistModal":_vm.watchlistModal,"watchlistData":_vm.watchlistData,"closeModal":() => {
                _vm.watchlistModal = false;
            }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }