<template>
    <v-dialog
        :hide-overlay="true"
        :persistent="true"
        v-model="alertModal"
        width="1000"
    >
        <v-card class="pt-0">
            <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
                style="background: rgb(26, 57, 88)"
            >
                <v-toolbar-title class="text-h6 white--text pl-0">
                    {{
                        alertModalType
                            ? capitalizeKey(alertModalType, "-", " ")
                            : null
                    }}
                    Alert Detail
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn color="white" icon @click="closeModal"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-app-bar>

            <v-card-actions>
                <v-row>
                    <template v-for="field in alertField[alertModalType]">
                        <v-col cols="2"
                            ><strong>{{ field.text }}</strong></v-col
                        >
                        <v-col cols="4">{{ alertDetail[field.key] }}</v-col>
                    </template>
                </v-row>
            </v-card-actions>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeModal"
                    >Close</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { helper } from "@components/mixins/helper";

export default {
    props: {
        alertModal: false,
        alertModalType: null,
        alertDetail: null,
        closeModal: Function,
    },
    mixins: [helper],
    data() {
        return {
            sortBy: undefined,
            sortDesc: undefined,
            offset: 30,
            currentPage: 1,
            alertInterval: null,
            isIBRequired: false,
            alertDataAmount: 0,
            alertData: [],
            loading: false,
            alertField: {
                "fast-trade": [
                    {
                        text: "Server",
                        key: "server",
                    },
                    {
                        text: "Login",
                        key: "user",
                    },
                    {
                        text: "Book",
                        key: "books",
                    },
                    {
                        text: "Symbol",
                        key: "symbol",
                    },
                    {
                        text: "Order",
                        key: "order",
                    },
                    {
                        text: "Volume",
                        key: "volume",
                    },
                    {
                        text: "Direction",
                        key: "direction",
                    },
                    {
                        text: "Action",
                        key: "action",
                    },
                    {
                        text: "Time Threshold",
                        key: "thresholdTime",
                    },
                    {
                        text: "Volume Threshold",
                        key: "thresholdVolume",
                    },
                ],
                "same-direction": [
                {
                        text: "Server",
                        key: "server",
                    },
                    {
                        text: "Login",
                        key: "login",
                    },
                    {
                        text: "Book",
                        key: "book",
                    },
                    {
                        text: "Symbol",
                        key: "symbol",
                    },
                    {
                        text: "Volume",
                        key: "volume",
                    },
                    {
                        text: "Total Volume",
                        key: "totalVolume",
                    },
                    {
                        text: "Order",
                        key: "order",
                    },
                    {
                        text: "Event",
                        key: "event",
                    },
                    {
                        text: "Action",
                        key: "action",
                    },
                    {
                        text: "Interval Threshold",
                        key: "threshold_interval",
                    },
                    {
                        text: "Number Threshold",
                        key: "threshold_number",
                    },
                    {
                        text: "Volume Threshold",
                        key: "threshold_volume",
                    },
                ],
                "big-lot": [
                    {
                        text: "Server",
                        key: "server",
                    },
                    {
                        text: "Login",
                        key: "login",
                    },
                    {
                        text: "Books",
                        key: "books",
                    },

                    {
                        text: "Symbol",
                        key: "symbol",
                    },
                    {
                        text: "Order",
                        key: "order",
                    },
                    {
                        text: "Volume",
                        key: "volume",
                    },
                    {
                        text: "Direction",
                        key: "direction",
                    },
                    {
                        text: "Action",
                        key: "action",
                    },
                    {
                        text: "Volume Threshold",
                        key: "thresholdVolume",
                    },
                ],
                "trade-on-credit": [
                    {
                        text: "Server",
                        key: "server",
                    },
                    {
                        text: "Login",
                        key: "login",
                    },
                    {
                        text: "Books",
                        key: "books",
                    },
                    {
                        text: "Equity",
                        key: "equity",
                    },
                    {
                        text: "Balance",
                        key: "balance",
                    },
                    {
                        text: "Credit",
                        key: "credit",
                    },
                    {
                        text: "Equity Balance Ratio Threshold",
                        key: "equityCreditRatioThreshold",
                    },
                ],
                "deposit-withdrawal": [
                {
                        text: "Server",
                        key: "server",
                    },
                    {
                        text: "Login",
                        key: "user",
                    },
                    {
                        text: "Book",
                        key: "books",
                    },
                    {
                        text: "Order",
                        key: "order",
                    },
                    {
                        text: "Action",
                        key: "action",
                    },
                    {
                        text: "Amount",
                        key: "amount",
                    },
                    {
                        text: "Threshold",
                        key: "withdrawDepositThreshold",
                    },
                ],
                "large-volume-usd": [
                    {
                        text: "Server",
                        key: "server",
                    },
                    {
                        text: "Login",
                        key: "user",
                    },
                    {
                        text: "Book",
                        key: "books",
                    },
                    {
                        text: "Symbol",
                        key: "symbol",
                    },
                    {
                        text: "Order",
                        key: "order",
                    },
                    {
                        text: "Volume USD",
                        key: "volumeUSD",
                    },
                    {
                        text: "Direction",
                        key: "direction",
                    },
                    {
                        text: "Action",
                        key: "action",
                    },
                    {
                        text: "Volume Threshold",
                        key: "largeVolumeUSDThreshold",
                    },
                ],
                "profit-taker": [
                    {
                        text: "Server",
                        key: "server",
                    },
                    {
                        text: "Login",
                        key: "user",
                    },
                    {
                        text: "Book",
                        key: "books",
                    },
                    {
                        text: "Symbol",
                        key: "symbol",
                    },
                    {
                        text: "Order",
                        key: "order",
                    },
                    {
                        text: "Volume",
                        key: "volume",
                    },
                    {
                        text: "Profit EOD",
                        key: "profitClosedEod",
                    },
                    {
                        text: "Profit Threshold",
                        key: "profitThreshold",
                    },
                ],
            },
        };
    },
};
</script>
